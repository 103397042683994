export const COIN_NAME = {
  ADA: "Cardano",
  BNB: "Binance Coin",
  BTC: "Bitcoin",
  BCH: "Bitcoin Cash",
  BSV: "Bitcoin SV",
  ATOM: "Cosmos",
  DASH: "Dash",
  DOGE: "Dogecoin",
  DAI: "Multi Collateral Dai",
  DOT: "Polkadot",
  EOS: "EOS",
  FIO: "FIO Protocol",
  FIRO: "Firo",
  ETH: "Ethereum",
  LTC: "Litecoin",
  LBC: "Library Credits",
  LINK: "Chainlink",
  RVN: "Ravencoin",
  XMR: "Monero",
  XRP: "Ripple",
  XLM: "Stellar",
  XTZ: "Tezos",
  TRX: "Tron",
  USDT: "Tether",
  ZEC: "Zcash",
};
export const SUPPORTED_COINS = [
  //{ value: 'BNB', label: COIN_NAME['BNB'] },
  { value: "BTC", label: COIN_NAME["BTC"] },
  { value: "BCH", label: COIN_NAME["BCH"] },
  { value: "BSV", label: COIN_NAME["BSV"] },
  //{ value: 'ATOM', label: COIN_NAME['ATOM'] },
  { value: "DASH", label: COIN_NAME["DASH"] },
  { value: "DOGE", label: COIN_NAME["DOGE"] },
  //{ value: 'EOS', label: COIN_NAME['EOS'] },
  { value: "ETH", label: COIN_NAME["ETH"] },
  { value: "LTC", label: COIN_NAME["LTC"] },
  //{ value: 'RVN', label: COIN_NAME['RVN'] },
  { value: "XMR", label: COIN_NAME["XMR"] },
  { value: "XRP", label: COIN_NAME["XRP"] },
  { value: "USDT", label: COIN_NAME["USDT"] },
  //{ value: 'ZEC', label: COIN_NAME['ZEC'] },
];

export const SUPPORTED_MESSAGE_COINS = [
  "BCH",
  //"BSV",
  "BTC",
  "DASH",
  "DOGE",
  "LTC",
  //"ZEC",
  "ETH",
];

export const URI = {
  BNB: "",
  BTC: "bitcoin",
  BCH: "bitcoincash",
  BSV: "bitcoin",
  ATOM: "",
  DASH: "dash",
  DOGE: "dogecoin",
  EOS: "EOS",
  ETH: "ethereum",
  LTC: "litecoin",
  RVN: "ravencoin",
  XMR: "monero",
  XRP: "xrpl",
  USDT: "tether",
  ZEC: "zcash",
};

// duplicated from function constants
export const BLOCKCHAIR_DICT = {
  BCH: "bitcoin-cash",
  BTC: "bitcoin",
  BSV: "bitcoin-sv",
  LTC: "litecoin",
  DOGE: "dogecoin",
  DASH: "dash",
  //GROESTL: 'groestlcoin',
  ZEC: "zcash",
  ETH: "ethereum",
}

export const BLOCKCHAIR_TXN_PREFIX = {
  ETH: "0x",
}

export const SOCIAL_MEDIA_SITES = {
  applePodcasts: "Apple Podcasts",
  bitchute: "BitChute",
  bittube: "BitTube",
  cashapp: "Cash App",
  discord: "Discord",
  dlive: "DLive",
  facebook: "Facebook",
  flote: "Flote.app",
  github: "GitHub",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  lbry: "LBRY",
  medium: "Medium",
  memo: "Memo.Cash",
  minds: "Minds",
  //'openBazaar': 'OpenBazaar',
  patreon: "Patreon",
  paypal: "PayPal",
  read: "Read.Cash",
  reddit: "Reddit",
  soundcloud: "SoundCloud",
  steemit: "Steemit",
  spotify: "Spotify",
  stitcher: "Stitcher",
  TikTok: "TikTok",
  twetch: "Twetch",
  telegram: "Telegram",
  twitch: "Twitch",
  twitter: "Twitter",
  web: "Website",
  youtube: "YouTube",
};

export const PHOTO_SIZE = 96;
export const MAX_SITE_WIDTH = 600;
